import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from './content.component';
import {Router} from '@angular/router';
import {RequestsService} from '../service/requests.service';
import {SpUtilService} from '../service/sp-util.service';
import {SharedService} from '../service/shared.service';
import {SelectItem} from 'primeng';
import {PlatformLocation} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CreateUser} from '../model/user/create.user';
import {APIURLConstants} from '../util/api.url.constants';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, timer} from 'rxjs';
import {CountryCode} from '../util/country-code';

@Component({
    selector: 'content-header',
    templateUrl: '../templates/app.header.template.html',
})
export class AppHeaderComponent implements OnInit {

    navbarCollapseClass: boolean = true;
    userSideDrawer: boolean = false;
    isShowAllSpecialities: boolean = false;
    moveMainMenuBar: boolean = false;
    showChildMenu: number = -1;
    createAccountModal: boolean;
    otpModal: boolean;
    signInModal: boolean;
    logoutModal: boolean;
    changePasswordModal: boolean;
    changePasswordSuccessModal: boolean;
    createUserForm: FormGroup;
    userOTPForm: FormGroup;
    otp: number;
    userId: number;
    customerId: number;
    showMobileNumber: string;
    email: string;
    password: string;
    userSignInForm: FormGroup;
    isLoggedIn: boolean;
    userName: string;
    userChangePasswordForm: FormGroup;
    webMainMenu: any[] = [];
    webAllSpecialitiesMenu: any[] = [];
    constWebAllSpecialitiesMenu: any[] = [];
    mobileCodes: any[] = CountryCode.countryCode;
    cartCount: number;
    changeMobileNoPopupVisible: boolean = false;
    newMobileNo: string;
    newMobileNoCode: any;
    fcmLogsCount: number = 0;
    fcmToken: any;
    forgotPasswordModalVisible: boolean;
    forgotPasswordForm: FormGroup;
    isGeneratePasswordLoading: boolean = false;
    countDown: Subscription;
    counter = 90;
    tick = 1000;
    passFieldType: boolean;
    confirmPassFieldType: boolean;

    constructor(public app: ContentComponent,
                private router: Router,
                private translate: TranslateService,
                private requestsService: RequestsService,
                public adminUtilService: SpUtilService,
                private sharedService: SharedService,
                private plateFormLocation: PlatformLocation,
                private toastService: ToastrService,
                private formBuilder: FormBuilder) {
        this.adminUtilService.userInfo = JSON.parse(localStorage.getItem(btoa('user-info')));
        this.buildForms();
    }

    ngOnInit() {
        if (window.localStorage.getItem(btoa('access_token'))) {
            this.requestsService.postRequest(
                APIURLConstants.LOGGED_IN_USER_URL,
                {'user_type': 'customer'})
                .subscribe(
                    (response: Response) => {
                        if (response['responseCode'] === 'USR_SUC_01') {
                            if (localStorage.getItem(btoa('user-info'))) {
                                const obj = JSON.parse(JSON.stringify(response['responseData'].customer));
                                localStorage.setItem(btoa('user-info'), JSON.stringify(obj));
                                this.adminUtilService.userInfo = obj;
                                this.sharedService.isUserLoggedIn = true;
                                this.sharedService.userLoggedIn.next(true);
                                this.sharedService.isUserLoggedIn = true;
                            }
                        }
                    },
                    (error: any) => {
                        this.adminUtilService.tokenExpired(error.status);
                    }
                );
            // when API is in process - synchronizing
            this.adminUtilService.userInfo = JSON.parse(localStorage.getItem(btoa('user-info')));

            this.isLoggedIn = true;
            this.userName = this.adminUtilService.userInfo.en_name;
            this.loadAllUnReadNotifications();
        } else {
            this.sharedService.fcmToken.subscribe((_token) => {
                this.fcmToken = _token;
            });
        }
        if (localStorage.getItem(btoa('CartItems'))) {
            let cartItems = JSON.parse(localStorage.getItem(btoa('CartItems')));
            this.cartCount = cartItems.length;
        } else {
            this.cartCount = 0;
        }
        this.toggleNavbarCollapseClass();
        this.loadWebMenus();
        this.loadAllSpecialities();

        this.sharedService.isLoginClick.subscribe(sub => {
            if (sub === true) {
                this.signInModal = true;
            }
        });
        this.sharedService.isCreateAccountClick.subscribe(sub => {
            if (sub === true) {
                this.createAccountModal = true;
            }
        });
        this.sharedService.newNotificationLoad.subscribe(res => {
            if (res) {
                this.loadAllUnReadNotifications();
            }
        });
    }

    ngOnDestroy() {
        this.countDown = null;
    }

    showCreateAccountModel() {
        this.otpModal = false;
        this.signInModal = false;
        this.createAccountModal = true;
    }

    buildUserRegistrationRequest(data: any): CreateUser {
        const user: CreateUser = new CreateUser();
        user.email = data.email;
        user.password = data.password;
        user.password_confirmation = data.password_confirmation;
        user.mobile_code = data.mobile_code;
        user.mobile_number = data.mobile_number;
        user.user_type = 'customer';
        user.is_active = 0;
        return user;
    }

    private buildForms() {
        this.createUserForm = this.formBuilder.group({
            'email': [null, Validators.compose([Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
            'password': [null, Validators.compose([Validators.required])],
            'password_confirmation': [null, Validators.compose([Validators.required])],
            'mobile_code': ['+966', Validators.compose([Validators.required])],
            'mobile_number': [null, Validators.compose([Validators.required])],
        });

        this.userOTPForm = this.formBuilder.group({
            'user_otp': [null, Validators.compose([Validators.required])]
        });

        this.userSignInForm = this.formBuilder.group({
            'username': [null, Validators.compose([Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
            'password': [null, Validators.compose([Validators.required])],
        });

        this.userChangePasswordForm = this.formBuilder.group({
            'old_password': [null, Validators.compose([Validators.required])],
            'new_password': [null, Validators.compose([Validators.required])],
            'confirm_password': [null, Validators.compose([Validators.required])],
        });

        this.forgotPasswordForm = this.formBuilder.group({
            'username': [null, Validators.compose([Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
        });
    }

    verificationStarted(data: any) {
        if (data.password != data.password_confirmation) {
            this.translate.get('error').subscribe((data: any) => {
                this.toastService.error(data['Password and Confirm Password does not match.'], data['Medicta']);
            });
            return;
        }

        let createUserRequest: CreateUser = this.buildUserRegistrationRequest(data);

        this.password = data.password;
        sessionStorage.setItem('tempUser', JSON.stringify(createUserRequest));
        this.requestsService.postRequestUnAuth(
            APIURLConstants.SEND_VERIFICATION_OTP_API_URL,
            {
                mobile_number: createUserRequest.mobile_code + createUserRequest.mobile_number,
                email: createUserRequest.email
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'CUS_SUC_05') {
                        this.otp = response['responseData'];
                        /*this.encryptMobileNumber(
                            {
                                mobile_number: createUserRequest.mobile_number,
                                mobile_country_code: createUserRequest.mobile_code
                            }
                        );*/
                        this.showMobileNumber = createUserRequest.mobile_code + '' + createUserRequest.mobile_number;
                        this.showOtpModel();
                    } else {
                        this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Create User');
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    encryptMobileNumber(data) {
        let mobileNo = data.mobile_number;
        this.showMobileNumber = data.mobile_country_code + '' + mobileNo.charAt(0) + 'xxxxxxxx';
    }

    showOtpModel() {
        this.createAccountModal = false;
        this.otpModal = true;
        this.countDown = timer(0, this.tick).subscribe(
            (() => this.counter > 0 ? --this.counter : this.counter = 0)
        );
    }

    signIn(data: any) {
        if (this.otp == data.user_otp) {
            let createUserRequest: CreateUser = JSON.parse(sessionStorage.getItem('tempUser'));
            this.requestsService.postRequestUnAuth(
                APIURLConstants.REGISTER_API_URL,
                createUserRequest)
                .subscribe(
                    (response: Response) => {
                        if (response['responseCode'] === 'CUS_SUC_01') {
                            this.otp = response['responseData'].otp;
                            this.userId = response['responseData'].user_id;
                            this.customerId = response['responseData'].id;
                            this.email = response['responseData'].email;

                            this.requestsService.putUnAuthRequest(
                                APIURLConstants.UPDATE_USER_STATUS_API_URL + '/' + this.userId,
                                {
                                    'status': 1,
                                    'user_name': this.email,
                                    'password': this.password,
                                    'url': this.plateFormLocation['location'].origin + '/customer/profile'

                                })
                                .subscribe(
                                    (response: Response) => {
                                        if (response['responseCode'] === 'CUS_SUC_02') {
                                            const obj = JSON.parse(JSON.stringify(response['responseData'].customer));
                                            localStorage.setItem(btoa('access_token'), response['token']);
                                            localStorage.setItem(btoa('user-info'), JSON.stringify(obj));

                                            this.otpModal = false;
                                            this.waitForDialog().then((result) => {
                                                if (result === true) {
                                                    this.router.navigate(['/customer/profile']);
                                                }
                                            });
                                        } else {
                                            this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Create User');
                                        }
                                        sessionStorage.clear();
                                    },
                                    (error: any) => {
                                        this.translate.get('error').subscribe((data: any) => {
                                            console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                                        });
                                        this.adminUtilService.tokenExpired(error.status);
                                    });
                        } else {
                            this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Create User');
                        }
                    },
                    (error: any) => {
                        this.translate.get('error').subscribe((data: any) => {
                            console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                        });
                        this.adminUtilService.tokenExpired(error.status);
                    });
        } else {
            this.translate.get('error').subscribe((data: any) => {
                this.toastService.error(data['Invalid OTP Code'], data['Medicta']);
            });
        }
    }

    showSignInModel() {
        this.otpModal = false;
        this.createAccountModal = false;
        this.signInModal = true;
    }

    logIn(data: any) {
        this.requestsService.postRequestUnAuth(
            APIURLConstants.LOGIN_API_URL,
            {
                'user_name': data.username,
                'password': data.password,
                'user_type': 'customer',
                'device_type': 'web',
                fcm_token: this.fcmToken,
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_01') {
                        const obj = JSON.parse(JSON.stringify(response['responseData'].customer));
                        localStorage.setItem(btoa('access_token'), response['token']);
                        localStorage.setItem(btoa('user-info'), JSON.stringify(obj));

                        this.signInModal = false;
                        this.sharedService.isUserLoggedIn = true;
                        this.sharedService.userLoggedIn.next(true);
                        this.waitForDialog().then((result) => {
                            if (result === true) {
                                this.router.navigate(['/customer/profile']);
                            }
                        });
                    } else {
                        this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Sign In User');
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                    this.adminUtilService.tokenExpired(error.status);
                });

    }

    navigateToTermsPage() {
        this.createAccountModal = false;
        this.waitForDialog().then((result) => {
            if (result === true) {
                this.router.navigate(['/term']);
            }
        });
    }

    waitForDialog() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, 1000);
        });
    }

    userLogout() {
        this.logoutModal = false;
        this.isLoggedIn = false;
        this.changePasswordSuccessModal = false;
        this.app.logout();
    }

    changePassword(data: any) {
        this.requestsService.putRequest(
            APIURLConstants.CHANGE_PASSWORD_API_URL + '/' + this.adminUtilService.userInfo.user_id,
            {
                'old_password': data.old_password,
                'password': data.new_password,
                'password_confirmation': data.confirm_password,
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'AUTH_SUC_04') {
                        this.changePasswordModal = false;
                        this.changePasswordSuccessModal = true;
                        this.toastService.success(response['responseMessage'], 'Change Password');
                    } else {
                        this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Change Password');
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    showAllSpecialities() {
        this.isShowAllSpecialities = (!this.isShowAllSpecialities);
    }

    toggleUserSideDrawer() {
        this.userSideDrawer = (!this.userSideDrawer);

        if (this.userSideDrawer === true) {
            document.body.classList.add('body-overflow-y-hidden');
        } else {
            document.body.classList.remove('body-overflow-y-hidden');
        }
    }

    @HostListener('window:resize')
    toggleNavbarCollapseClass() {
        if (window.innerWidth == 1024) {
            this.navbarCollapseClass = false;
        } else {
            this.navbarCollapseClass = true;
        }
    }

    showHideChildMainMenu(event, index) {
        this.moveMainMenuBar = (!this.moveMainMenuBar);
        this.showChildMenu = index;
        if (this.moveMainMenuBar == false) {
            this.showChildMenu = -1;
        }
    }

    loadWebMenus() {
        this.requestsService.getRequestUnAuth(APIURLConstants.getAllWebMenus)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'MENU_SUC_01') {
                        let allMenus = response['responseData'];
                        allMenus = allMenus.filter(m => m.main_menu == 1);
                        this.webMainMenu = allMenus.slice(0, 8);
                        // this.webAllSpecialitiesMenu = allMenus.filter(m => m.speciality_menu == 1);
                        // this.constWebAllSpecialitiesMenu = JSON.parse(JSON.stringify(this.webAllSpecialitiesMenu));
                    } else {
                        this.webMainMenu = [];
                        // this.webAllSpecialitiesMenu = [];
                        // this.constWebAllSpecialitiesMenu = [];
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                });
    }

    loadAllSpecialities() {
        this.requestsService.getRequestUnAuth(APIURLConstants.getAllSpecialities)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'SPE_SUC_01') {
                        this.webAllSpecialitiesMenu = response['responseData'];
                        this.constWebAllSpecialitiesMenu = JSON.parse(JSON.stringify(this.webAllSpecialitiesMenu));
                    } else {
                        this.webAllSpecialitiesMenu = [];
                        this.constWebAllSpecialitiesMenu = [];
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                });
    }

    onSignInModelHide() {
    }

    onCreateAccountModelHide() {
        this.sharedService.isCreateAccountClick.next(false);
    }
    getAllMedicalServiceById(id) {
        this.requestsService.getRequestUnAuth(APIURLConstants.GET_ALL_MS_BY_ID+id)
            .subscribe(
                (response: Response) => {
                    localStorage.setItem(btoa('hospital-services'), JSON.stringify(response["responseData"]));
                    this.router.navigate(['/hospital-service/selection']);
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                });
    }
    async searchDoctors(service: any, is_lab_or_diagnostic = 0) {
        console.log(service)
        let spId = 'all', sId = 'all', cId = 'all', ciId = 'all';
        if (service) {
            sId = service.id;
        }
        if (is_lab_or_diagnostic) {
            await this.getAllMedicalServiceById(service.id)

        } else {
            this.router.navigate(['/doctors'],
                {
                    queryParams: {
                        speciality_id: spId,
                        service_id: sId,
                        country_id: cId,
                        city_id: ciId,
                        look_for: ''
                    }
                }
            );
        }
    }

    moveToAlerts() {
        this.clearAllNotificationCount()
        this.router.navigate(['/customer/profile'],
            {queryParams: {activeTab: 'Alerts'}}
        );
    }

    onSearchStringChange(value: string) {
        if (value) {
            this.webAllSpecialitiesMenu = this.constWebAllSpecialitiesMenu.filter(cs => cs.speciality.en_name.toLowerCase().includes(value.toLowerCase()));
        } else {
            this.webAllSpecialitiesMenu = JSON.parse(JSON.stringify(this.constWebAllSpecialitiesMenu));
        }
    }

    resendOTPSms() {

        if (this.showMobileNumber && this.counter == 0) {
            const params = {
                mobile_no: this.showMobileNumber,
            };
            this.requestsService.postRequest(APIURLConstants.RESEND_OTP_PHONE_NUMBER_API_URL, params).subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'CUS_SUC_05') {
                        this.otp = response['responseData'];
                        this.toastService.success(response['responseMessage'], 'Resend OTP');
                    } else {
                        this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], response['responseStatus']);
                    }
                    this.countDown.unsubscribe();
                    this.counter = 90;
                    this.countDown = timer(0, this.tick).subscribe(
                        (() => this.counter > 0 ? --this.counter : this.counter = 0)
                    );
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                });


            // this.requestsService.getRequestUnAuth(APIURLConstants.RESEND_OTP_PHONE_NUMBER_API_URL
            //     + this.showMobileNumber)
            //     .subscribe(
            //         (response: Response) => {
            //             if (response['responseCode'] === 'CUS_SUC_05') {
            //                 this.otp = response['responseData'].otp;
            //                 this.toastService.success(response['responseMessage'], 'Resend OTP');
            //             } else {
            //                 this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Resend OTP');
            //             }
            //             this.countDown.unsubscribe();
            //             this.counter = 90;
            //             this.countDown = timer(0, this.tick).subscribe(
            //                 (() => this.counter > 0 ? --this.counter : this.counter = 0)
            //             );
            //         },
            //         (error: any) => {
            //             this.toastService.error('Internal Server Error. Please contact administrator.',
            //                 'Resend OTP');
            //         });
        }
    }

    onChangePhoneNoClick() {
        this.changeMobileNoPopupVisible = true;
        this.counter = 0;
        this.countDown = timer(0, this.tick).subscribe(
            (() => this.counter > 0 ? --this.counter : this.counter = 0)
        );
    }

    changeMobileNo() {
        let requestData = {
            mobile_number: this.newMobileNo,
            mobile_code: this.newMobileNoCode?.value
        };

        this.requestsService.postRequest(
            APIURLConstants.CHANGE_CUSTOMER_MOBILE_NO_API_URL,
            requestData)
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'CUS_SUC_06') {
                        this.changeMobileNoPopupVisible = false;
                        this.otp = response['responseData'];
                        this.showMobileNumber = this.newMobileNoCode?.value + '' + this.newMobileNo;

                        this.countDown.unsubscribe();
                        this.counter = 90;
                        this.countDown = timer(0, this.tick).subscribe(
                            (() => this.counter > 0 ? --this.counter : this.counter = 0)
                        );
                        this.toastService.success(response['responseMessage'], 'Change Mobile No.');
                    } else {
                        this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Change Mobile No.');
                    }
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                    this.adminUtilService.tokenExpired(error.status);
                });
    }

    loadAllUnReadNotifications() {
        this.requestsService.getRequest(
            APIURLConstants.GET_ALL_UNREAD_FCM_NOTIFS_API_URL + this.adminUtilService.userInfo.user_id+"?is_customer=1")
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'NOTIFY_SUC_01') {
                        let fcmLogs = response['responseData'];
                        this.fcmLogsCount = fcmLogs.length;
                    }
                },
                (error: any) => {
                    this.adminUtilService.tokenExpired(error.status);
                });

    }
    clearAllNotificationCount() {
        if(this.fcmLogsCount === 0) return
        this.requestsService.putRequest(APIURLConstants.CLEAR_ALL_NOTIFICATIONS + this.adminUtilService["userInfo"].user_id+"?is_customer=1").subscribe(
            (response: Response) => {
                if (response['responseCode'] === 'NOTIFY_SUC_02') {
                    // this.allNotifications = []
                    this.fcmLogsCount = 0
                }
            }
        );
    }
    onForgotPasswordPopupHide() {
        this.forgotPasswordModalVisible = false;
        if(!this.isChangePasswordModalForgetModal){
            this.signInModal = true;
        }
    }

    showForgotPopup() {
        this.signInModal = false;
        this.forgotPasswordModalVisible = true;
    }

    generatePassword(data: any) {
        this.isGeneratePasswordLoading = true;
        this.requestsService.postRequestUnAuth(
            APIURLConstants.FORGOT_PASSWORD_API_URL,
            {
                email: data.username
            })
            .subscribe(
                (response: Response) => {
                    if (response['responseCode'] === 'CUS_PASS_CHA_01') {
                        this.forgotPasswordModalVisible = false;
                        this.signInModal = true;
                        this.toastService.success(response['responseMessage'], 'Forgot Password');
                    } else {
                        this.toastService.error(this.adminUtilService.selectedLanguage === 'en'?response['responseMessage']:response['name_ar'], 'Forgot Password');
                    }
                    this.isGeneratePasswordLoading = false;
                },
                (error: any) => {
                    this.translate.get('error').subscribe((data: any) => {
                        console.log(data['Internal Server Error. Please contact administrator.'], data['Medicta']);
                    });
                    this.adminUtilService.tokenExpired(error.status);
                    this.isGeneratePasswordLoading = false;
                });
    }

    changeLanguage(language: string) {
        this.adminUtilService.changeLayoutOrientation(language, 'header');
        this.translate.use(language);
        localStorage.setItem('selectedLanguage', language);
        window.location.reload();
    }

    public setSignUpData() {
        this.showCreateAccountModel();
        const createUserRequest: CreateUser = JSON.parse(sessionStorage.getItem('tempUser'));

        this.createUserForm.patchValue({
            email: createUserRequest.email,
            password: createUserRequest.password,
            password_confirmation: createUserRequest.password_confirmation,
            mobile_code: createUserRequest.mobile_code,
            mobile_number: createUserRequest.mobile_number,
        });
    }

    togglePassField() {
        this.passFieldType = !this.passFieldType;
    }

    toggleConfirmPassField() {
        this.confirmPassFieldType = !this.confirmPassFieldType;
    }
    @ViewChild("currentPassword") currentPassword: ElementRef;
    @ViewChild("newPassword") newPassword: ElementRef;
    @ViewChild("confirmPassword") confirmPassword: ElementRef;
    togglePassShowHide(input) {
        input.type = input.type ===  "text" ? "password" : "text"
    }
    isChangePasswordModalForgetModal: boolean = false;
    changePasswordModalForgetModalOpen(){
        this.changePasswordModal = false
        this.forgotPasswordModalVisible = true
        this.isChangePasswordModalForgetModal = true
    }
}
