import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class SharedService {

    emitter: Subject<any> = new Subject<any>();
    isLoginClick: Subject<boolean> = new Subject();
    isCreateAccountClick: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isUserLoggedIn: boolean = false;
    userLoggedIn: Subject<boolean> = new Subject();
    public fcmToken = new Subject();
    newNotificationLoad: Subject<boolean> = new Subject();

}

